import React from "react";
import Slider from "react-slick";

import amazonLogo from "../../images/client logos/amazon.avif";
import bestbuyLogo from "../../images/client logos/best buy.avif";
import chewyLogo from "../../images/client logos/chewy.avif";
import dollartreeLogo from "../../images/client logos/dollar tree.avif";
import medlineLogo from "../../images/client logos/medline.avif";
import pascoLogo from "../../images/client logos/pasco.avif";
import zephyrhillsLogo from "../../images/client logos/zephyrhills.avif";
import metro from "../../images/client logos/metro.avif";
import miller from "../../images/client logos/miller.avif";
import welbro from "../../images/client logos/welbro.avif";
import itasca from "../../images/client logos/itasca.avif";
import advent from "../../images/client logos/advent.avif";
import arco from "../../images/client logos/arco.avif";
import brasfield from "../../images/client logos/brasfield.avif";
import contravest from "../../images/client logos/contravest.avif";
import costco from "../../images/client logos/costco.avif";
import frampton from "../../images/client logos/frampton.avif";
import hernando from "../../images/client logos/hernando.avif";
import lennar from "../../images/client logos/lennar.avif";
import robins from "../../images/client logos/robins.avif";
import roomstogo from "../../images/client logos/roomstogo.avif";
import wawa from "../../images/client logos/wawa.avif";
import bjs from "../../images/client logos/bjs.png";
import edTaylor from "../../images/client logos/edTaylor.png";
import whartonSmith from "../../images/client logos/wharton.png";
import williamA from "../../images/client logos/williamA.png";
import academy from "../../images/client logos/academy.png";
import hmlogo from "../../images/client logos/hmlogo.png";
import BattenShaw from "../../images/client logos/BattenShaw.png";
import BrooksvilleRegionalAirport from "../../images/client logos/BrooksvilleRegionalAirport.png";
import DadeCity from "../../images/client logos/DadeCity.png";
import DobbsEquipment from "../../images/client logos/DobbsEquipment.png";
import HCA from "../../images/client logos/HCA.png";
import Kohls from "../../images/client logos/Kohls.png";
import MettlerToledo from "../../images/client logos/MettlerToledo.png";
import OReily from "../../images/client logos/OReilly.png";
import Publix from "../../images/client logos/Publix.png";
import Redstone from "../../images/client logos/Redstone.png";
import RestaurantDepot from "../../images/client logos/RestaurantDepot.png";
import Rooker from "../../images/client logos/Rooker.png";
import Walmart from "../../images/client logos/Walmart.png";

const ClientLogoSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 777,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const logos = [
    amazonLogo,
    bestbuyLogo,
    chewyLogo,
    dollartreeLogo,
    medlineLogo,
    advent,
    costco,
    roomstogo,
    wawa,
    bjs,
    MettlerToledo,
    Publix,
    Kohls,
    Walmart,
    RestaurantDepot,
    OReily,
    DobbsEquipment,
    HCA,

    pascoLogo,
    zephyrhillsLogo,
    hernando,
    lennar,
    academy,
    DadeCity,
    BrooksvilleRegionalAirport,

    metro,
    miller,
    welbro,
    itasca,
    arco,
    brasfield,
    contravest,
    frampton,
    robins,
    edTaylor,
    whartonSmith,
    williamA,
    hmlogo,
    BattenShaw,
    Redstone,
    Rooker,
  ];

  return (
    <div className="client-logo-slider">
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <div key={index} style={{ padding: "10px" }}>
            <div className="client-box">
              <div className="client-thumbnail">
                <img
                  className="img-fluid auto_size"
                  width={160}
                  height={98}
                  src={logo}
                  alt={`Client logo ${index + 1}`}
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ClientLogoSlider;
