import React from "react";
import Project from "../../../projects.json";

function hernandoLandfill() {
  const project = Project.projects.find((project) => project.id === 25);

  const currentUrl = window.location.href;
  const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    currentUrl
  )}`;
  const liUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    currentUrl
  )}`;

  function shareToFacebook() {
    window.open(fbUrl, "_blank", "width=600,height=400");
  }

  function shareToLinkedIn() {
    window.open(liUrl, "_blank", "width=600,height=400");
  }

  return (
    <>
      <div className="ttm-page-title ttm-bg clearfix">
        <div className="ttm-row-wrapper-bg-layer" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="ttm-page-title-row-inner">
                <div className="page-title-heading">
                  <h2 className="title">{project.title}</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <a href="/projects">&nbsp; Projects</a>
                  </span>
                  <span>Project Details</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-main">
        <section className="ttm-row project-single-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ttm-pf-single-content-wrapper">
                  <div className="ttm-pf-single-content-wrapper-innerbox">
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Location:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.location}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Started:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.started}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Completed:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.ended}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="margin_bottom50 margin_top50 portfolio-single text-center position-relative">
                            <ul className="social-icons list-inline">
                              <li>
                                <a
                                  id="facebook-share-btn"
                                  onClick={shareToFacebook}
                                >
                                  <i className="fa fa-facebook" />
                                </a>
                              </li>
                              <li>
                                <a
                                  id="linkedin-share-btn"
                                  onClick={shareToLinkedIn}
                                >
                                  <i className="fa fa-linkedin" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="slider ttm_pf_image-wrapper">
                            <div>
                              <a
                                data-lightbox="landfill-top"
                                href="/images/projects/hernando landfill/DJI_0050.jpg"
                              >
                                <img
                                  alt="Slide 1"
                                  className="img-fluid"
                                  src="/images/projects/hernando landfill/DJI_0050.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="landfill-top"
                                href="/images/projects/hernando landfill/DJI_0047.jpg"
                              >
                                <img
                                  alt="Slide 2"
                                  className="img-fluid"
                                  src="/images/projects/hernando landfill/DJI_0047.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="landfill-top"
                                href="/images/projects/hernando landfill/DJI_0089.jpg"
                              >
                                <img
                                  alt="Slide 3"
                                  className="img-fluid"
                                  src="/images/projects/hernando landfill/DJI_0089.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="landfill-top"
                                href="/images/projects/hernando landfill/DJI_0077.jpg"
                              >
                                <img
                                  alt="Slide 4"
                                  className="img-fluid"
                                  src="/images/projects/hernando landfill/DJI_0077.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="landfill-top"
                                href="/images/projects/hernando landfill/DJI_0074.jpg"
                              >
                                <img
                                  alt="Slide 5"
                                  className="img-fluid"
                                  src="/images/projects/hernando landfill/DJI_0074.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="landfill-top"
                                href="/images/projects/hernando landfill/DJI_0072.jpg"
                              >
                                <img
                                  alt="Slide 6"
                                  className="img-fluid"
                                  src="/images/projects/hernando landfill/DJI_0072.jpg"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="ttm-pf-single-content-area">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="margin_top40">
                                <h2>{project.title}</h2>
                                <p>
                                  BRW is currently making remarkable progress in
                                  the construction of a cutting-edge 30-acre
                                  landfill cell and its associated stormwater
                                  pond in Hernando County. The scope of work
                                  encompasses precise excavation and onsite
                                  stockpiling of an impressive 2.1 million cubic
                                  yards of diverse materials, including sand,
                                  clay, and limerock. The project's standout
                                  feature lies in the effective deployment of a
                                  skilled workforce operating state-of-the-art
                                  machinery. To achieve the ambitious timeline,
                                  BRW has harnessed the power of 4 to 5 large
                                  excavators, 3 to 4 large dozers, and an
                                  impressive fleet of 20 - 30 and 40-ton
                                  off-road dump trucks. <br />
                                  <br />
                                  This ongoing project showcases BRW's
                                  unwavering commitment to delivering
                                  exceptional site development solutions,
                                  upholding the highest standards of quality,
                                  safety, and efficiency throughout the
                                  construction process. Stay tuned as BRW
                                  continues to make substantial strides in
                                  shaping a sustainable and forward-looking
                                  landfill facility for the community of
                                  Hernando County.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="landfill-bottom"
                                  href="/images/projects/hernando landfill/DJI_0100.jpg"
                                >
                                  <img
                                    alt="single-img-10"
                                    className="img-fluid"
                                    src="/images/projects/hernando landfill/DJI_0100.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="landfill-bottom"
                                  href="/images/projects/hernando landfill/DJI_0106.jpg"
                                >
                                  <img
                                    alt="single-img-11"
                                    className="img-fluid"
                                    src="/images/projects/hernando landfill/DJI_0106.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default hernandoLandfill;
