import React from "react";

//React-router
import { Routes, Route } from "react-router-dom";

//Componets
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

//Pages
import Home from "./pages/home/Home";
import Services from "./pages/services/Services";
import Careers from "./pages/careers/Careers";
import About from "./pages/about/About";
import Projects from "./pages/projects/Projects";
import Team from "./pages/team/Team";
import Contact from "./pages/contact/Contact";
import Concrete from "./pages/services/Concrete";
import Earthwork from "./pages/services/Earthwork";
import PavingStriping from "./pages/services/PavingStriping";
import RoadwayConstruction from "./pages/services/RoadwayConstruction";
import UndergroundUtilities from "./pages/services/UndergroundUtilities";
import SegmentedRetainingWalls from "./pages/services/segmentedRetainingWalls";
import Error from "./pages/error/Error";

//Projects
import BoarsHeadRanch from "./pages/projects/Boarshead Ranch/BoarsHeadRanch";
import DollarTreePhase1 from "./pages/projects/Dollar Tree Phase 1/DollarTreePhase1";
import DollarTreePhase2 from "./pages/projects/Dollar Tree Phase 2/DollarTreePhase2";
import OakCreekParkway from "./pages/projects/Oak Creek/OakCreek";
import LongBayou from "./pages/projects/Long Bayou/LongBayou";
import ParcWesleyChapel from "./pages/projects/Parc at Wesley Chapel/ParcWesleyChapel";
import BestBuyPolkCity from "./pages/projects/Best Buy Polk City/BestBuyPolkCity";
import CenterstateLogistics from "./pages/projects/Centerstate Logistics/CenterstateLogistics";
import EppersonCommercial from "./pages/projects/Epperson Commercial/eppersonCommercial";
import Sr50 from "./pages/projects/SR 50/sr50";
import Sr52 from "./pages/projects/SR 52/sr52";
import HernandoLandfill from "./pages/projects/Hernando County Landfill/hernandoLandfill";
import LexingtonCommons from "./pages/projects/Lexington Oaks Commons/lexingtonCommons";
import LexingtonApartments from "./pages/projects/Lexington Oaks Apartments/lexingtonApartments";
import EppersonA1A5 from "./pages/projects/Epperson A1-A5/eppersonA1A5";
import SovereignTownCenter from "./pages/projects/Sovereign Town Center/soverieignTownCenter";
import Volaris from "./pages/projects/Volaris/volaris";
import CostcoClermont from "./pages/projects/Costco Clermont/costcoClermont";
import ZephyrCourt from "./pages/projects/Zephyr Court/zephyrCourt";
import SunlakeBusinessCenter from "./pages/projects/Sunlake Business Center/sunlakeBusinessCenter";
import NorthTampa75BusinessCenter from "./pages/projects/North Tampa Bay 75 Business Center/northTampa75BusinessCenter";
import SumterCountyBushnellUtility from "./pages/projects/Sumter County Bushnell Utility/sumterCountyBushnellUtility";
import AdventHealthWinterHaven from "./pages/projects/Advent Health Winter Haven OSED/AdventHealthWinterHaven";
import AngelinePhase4 from "./pages/projects/Angeline Phase 4 Mass Grading/angelinePhase4";
import PosnerBusinessCenter from "./pages/projects/Posner Business Center/posnerBusinessCenter";
import CityOfZephyrhillsSidewalk from "./pages/projects/City of Zephyrhills Sidewalk/cityOfZephyrhillsSidewalk";
import DairyRoadPhase2 from "./pages/projects/Dairy Road Phase 2/dairyRoadPhase2";
import RestaurantDepotHainesCity from "./pages/projects/Restaurant Depot Haines City/RestaurantDepotHainesCity";
import LargoIndustrialBuilding1 from "./pages/projects/Largo Industrial Building 1/LargoIndustrialBuilding1";
import TampaCommerce from "./pages/projects/Tampa Commerce/tampaCommerce";
import HarneyRd from "./pages/projects/Harney rd/harneyRd";
import SimonsRd from "./pages/projects/Simons rd/simonsRd";
import TyndallRd from "./pages/projects/Tyndall Rd/tyndallRd";
import MiradaBlvdPhase4 from "./pages/projects/Mirada Blvd Phase 4/miradaBlvdPhase4";
import MiradaRoundabout from "./pages/projects/Mirada Roundabout/miradaRoundabout";
import KentonRd from "./pages/projects/Kenton Rd/kentonRd";
import CurlyRdSegmentB from "./pages/projects/Curly Rd Segment B/curlyRdSegmentB";
import AuburndaleWarehouse from "./pages/projects/Auburndale Warehouse/AuburndaleWarehouse";
import ZephyrhillsImprovements from "./pages/projects/Zephyrhills Improvements/zephyrhillsImprovements";
import AngelineGrandLiveOak from "./pages/projects/Angeline Grand Live Oak/angelineGrandLiveOak";
import AdventHealthNorthDaleMabry from "./pages/projects/Advent Health North Dale Mabry/AdventHealthNorthDaleMabry";
import HerculesPark from "./pages/projects/Hercules Park/HerculesPark";
import LakeHideaway from "./pages/projects/Lake Hideaway/LakeHideaway";
import OldPascoRd from "./pages/projects/Old Pasco Rd/OldPascoRd";
import MiradaParcelPhase1_2 from "./pages/projects/Mirada Parcel Phase1-2/MiradaParcelPhase1_2";
import RoyalHighlands from "./pages/projects/Royal Highlands/RoyalHighlands";
import PowellRoad from "./pages/projects/Powell Road 100 & 200/PowellRoad";
import HighlandTrails from "./pages/projects/Highland Trails/HighlandTrails";

function App() {
  return (
    <div className="App">
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/team" element={<Team />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/concrete" element={<Concrete />} />
        <Route path="/earthwork" element={<Earthwork />} />
        <Route path="/pavingStriping" element={<PavingStriping />} />
        <Route path="/roadwayConstruction" element={<RoadwayConstruction />} />
        <Route
          path="/segmentedRetainingWalls"
          element={<SegmentedRetainingWalls />}
        />
        <Route
          path="/undergroundUtilities"
          element={<UndergroundUtilities />}
        />
        <Route path="/error" element={<Error />} />
        <Route path="/BoarsHeadRanch" element={<BoarsHeadRanch />} />
        <Route path="/DollarTreePhase1" element={<DollarTreePhase1 />} />
        <Route path="/DollarTreePhase2" element={<DollarTreePhase2 />} />
        <Route path="/OakCreekParkway" element={<OakCreekParkway />} />
        <Route path="/Longbayou" element={<LongBayou />} />
        <Route path="/ParcWesleyChapel" element={<ParcWesleyChapel />} />
        <Route path="/BestBuyPolkCity" element={<BestBuyPolkCity />} />
        <Route
          path="/CenterstateLogistics"
          element={<CenterstateLogistics />}
        />
        <Route path="/EppersonCommercial" element={<EppersonCommercial />} />
        <Route path="/Sr50" element={<Sr50 />} />
        <Route path="/Sr52" element={<Sr52 />} />
        <Route path="/HernandoLandfill" element={<HernandoLandfill />} />
        <Route path="/LexingtonCommons" element={<LexingtonCommons />} />
        <Route path="/LexingtonApartments" element={<LexingtonApartments />} />
        <Route path="/EppersonA1A5" element={<EppersonA1A5 />} />
        <Route path="/SovereignTownCenter" element={<SovereignTownCenter />} />
        <Route path="/Volaris" element={<Volaris />} />
        <Route path="/CostcoClermont" element={<CostcoClermont />} />
        <Route path="/ZephyrCourt" element={<ZephyrCourt />} />
        <Route
          path="/SunlakeBusinessCenter"
          element={<SunlakeBusinessCenter />}
        />
        <Route
          path="/NorthTampa75BusinessCenter"
          element={<NorthTampa75BusinessCenter />}
        />
        <Route
          path="/SumterCountyBushnellUtility"
          element={<SumterCountyBushnellUtility />}
        />
        <Route
          path="/AdventHealthWinterHaven"
          element={<AdventHealthWinterHaven />}
        />
        <Route path="/AngelinePhase4" element={<AngelinePhase4 />} />
        <Route
          path="/PosnerBusinessCenter"
          element={<PosnerBusinessCenter />}
        />
        <Route
          path="/CityOfZephyrhillsSidewalk"
          element={<CityOfZephyrhillsSidewalk />}
        />
        <Route path="/DairyRoadPhase2" element={<DairyRoadPhase2 />} />
        <Route
          path="/RestaurantDepotHainesCity"
          element={<RestaurantDepotHainesCity />}
        />
        <Route
          path="/LargoIndustrialBuilding1"
          element={<LargoIndustrialBuilding1 />}
        />
        <Route path="/tampaCommerce" element={<TampaCommerce />} />
        <Route path="/harneyRd" element={<HarneyRd />} />
        <Route path="/simonsRd" element={<SimonsRd />} />
        <Route path="/tyndallRd" element={<TyndallRd />} />
        <Route path="/miradaBlvdPhase4" element={<MiradaBlvdPhase4 />} />
        <Route path="/miradaRoundabout" element={<MiradaRoundabout />} />
        <Route path="/kentonRd" element={<KentonRd />} />
        <Route path="/curlyRdSegmentB" element={<CurlyRdSegmentB />} />
        <Route path="/AuburndaleWarehouse" element={<AuburndaleWarehouse />} />
        <Route
          path="/zephyrhillsImprovements"
          element={<ZephyrhillsImprovements />}
        />
        <Route
          path="/angelineGrandLiveOak"
          element={<AngelineGrandLiveOak />}
        />
        <Route
          path="/AdventHealthNorthDaleMabry"
          element={<AdventHealthNorthDaleMabry />}
        />
        <Route path="/HerculesPark" element={<HerculesPark />} />
        <Route path="/LakeHideaway" element={<LakeHideaway />} />
        <Route path="/OldPascoRd" element={<OldPascoRd />} />
        <Route
          path="/MiradaParcelPhase1_2"
          element={<MiradaParcelPhase1_2 />}
        />
        <Route path="/RoyalHighlands" element={<RoyalHighlands />} />
        <Route path="/PowellRoad" element={<PowellRoad />} />
        <Route path="/HighlandTrails" element={<HighlandTrails />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
