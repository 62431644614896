import React from "react";
import AboutPhoto from "../../images/IMG_5072.jpg";
import Photo2 from "../../images/Image42222.jpg";

function About() {
  return (
    <>
      <div className="ttm-page-title ttm-bg clearfix">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="ttm-page-title-row-inner">
                <div className="page-title-heading">
                  <h2 className="title">About Us</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <a title="Homepage" href="/">
                      <i className="fa fa-house" /> &nbsp; Home
                    </a>
                  </span>
                  <span>About</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-main">
        <section className="ttm-row about-section clearfix">
          <div className="container">
            <div className="row g-20 res-991-padding_lr15">
              <div className="col-lg-6">
                <div className="section-title">
                  <div className="title-header">
                    <h3>ABOUT US</h3>
                    <h2 className="title">
                      Trusted Expertise, <span>Unmatched</span> Quality
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p>
                      Established in 2001, BRW is a Central Florida full-service
                      site development contracting company capable of handling
                      your site work needs. BRW is located in Land O’ Lakes,
                      Florida. We are a Florida State Certified Underground
                      Utility and Excavation Contractor. We also hold a State
                      Certification for Class 5 underground fire protection from
                      the division of state fire marshal, allowing us to install
                      any type of underground fire protection that any building
                      may require. BRW’s business philosophy is dedicated to
                      trusting relationships, excellent service, workmanship
                      that has enduring character and meeting or exceeding
                      customer’s expectations every time. The company completes
                      many smaller projects each year, but specializes in
                      delivering large-scale projects with difficult tasks,
                      conditions, and schedules.
                    </p>
                  </div>
                </div>
                <div
                  className="ttm-tabs ttm-tab-style-04 clearfix margin_top30"
                  data-effect="fadeIn"
                >
                  <ul className="tabs">
                    <li className="tab active">
                      <a href="#">Mission</a>
                    </li>
                    <li className="tab">
                      <a href="#">Service</a>
                    </li>
                    <li className="tab">
                      <a href="#">Vision</a>
                    </li>
                  </ul>
                  <div className="content-tab padding_top30 padding_bottom30">
                    <div className="content-inner active">
                      <div className="ttm-tabs-desc">
                        <p>
                          At BRW, our mission is to deliver high-quality,
                          cost-effective site development and construction
                          services to our clients. We strive to be a trusted
                          partner to our clients and to build long-lasting
                          relationships based on honesty, transparency, and
                          mutual respect. We are committed to safety,
                          sustainability, and innovation, and we are dedicated
                          to building better communities one project at a time.
                        </p>
                      </div>
                    </div>

                    <div className="content-inner">
                      <div className="ttm-tabs-desc">
                        <p>
                          Our full scope of services Includes Complete site
                          development including earthwork, underground
                          utilities, concrete, roadway construction, and paving
                          and striping. Our projects include commercial,
                          multi-family, roadway, industrial, healthcare,
                          single-family, municipality, and environmental.
                        </p>
                      </div>
                    </div>
                    <div className="content-inner">
                      <div className="ttm-tabs-desc">
                        <p>
                          Our vision at BRW is to be the premier site
                          development and construction company in our region. We
                          aspire to be known for our exceptional service,
                          skilled workforce, and innovative solutions that
                          exceed our clients' expectations. We aim to create
                          value for our clients, employees, and shareholders by
                          continuously improving our operations, fostering a
                          culture of excellence and innovation, and investing in
                          our people and resources. We envision a future where
                          we are at the forefront of the industry, making a
                          positive impact on the communities we serve.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="ttm_single_image-wrapper">
                  <img
                    className="img-fluid auto_size"
                    src={AboutPhoto}
                    alt="single-06"
                    height={697}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ttm-row bg-layer-equal-height clearfix ttm-bgcolor-grey">
          <div className="container">
            <div className="row g-0 ttm-bgcolor-grey">
              <div className="col-lg-6">
                <div className="ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-grey ttm-bg ttm-left-span padding_top70 padding_right70 padding_bottom30 padding_left15 res-991-padding_lr15 res-1199-padding_left30">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                    <div className="ttm-col-wrapper-bg-layer-inner" />
                  </div>
                  <div className="layer-content">
                    <div className="section-title">
                      <div className="title-header">
                        <h3>WHY CHOOSE US</h3>
                        <h2 className="title">
                          We Go The <span>Extra Mile</span> <br />
                          To Get The Job Done!
                        </h2>
                      </div>
                      <div className="title-desc">
                        <p>
                          At BRW, we understand that you have a choice when it
                          comes to your construction and site development needs.
                          That's why we are committed to providing our clients
                          with the highest level of service and expertise, no
                          matter the size or complexity of the project. With
                          decades of experience and a proven track record of
                          success, we have the skills, knowledge, and resources
                          to deliver your project on schedule and exceed your
                          expectations. When you choose BRW, you're choosing a
                          partner that is dedicated to your success and building
                          better communities together.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ttm-bg ttm-col-bgimage-yes col-bg-img-six z-index-2">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" />
                  <div className="layer-content" />
                </div>

                <img
                  className="img-fluid ttm-equal-height-image w-100"
                  src={Photo2}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default About;
