import React, { useEffect, useRef, useState } from "react";
import "slick-carousel";
import Hero from "../../components/hero/Hero";
import Photo1 from "../../images/DSC_0263_edit.jpg";

import amazonLogo from "../../images/client logos/amazon.avif";
import bestbuyLogo from "../../images/client logos/best buy.avif";
import chewyLogo from "../../images/client logos/chewy.avif";
import dollartreeLogo from "../../images/client logos/dollar tree.avif";
import medlineLogo from "../../images/client logos/medline.avif";
import pascoLogo from "../../images/client logos/pasco.avif";
import zephyrhillsLogo from "../../images/client logos/zephyrhills.avif";
import metro from "../../images/client logos/metro.avif";
import miller from "../../images/client logos/miller.avif";
import welbro from "../../images/client logos/welbro.avif";
import itasca from "../../images/client logos/itasca.avif";
import advent from "../../images/client logos/advent.avif";
import arco from "../../images/client logos/arco.avif";
import brasfield from "../../images/client logos/brasfield.avif";
import contravest from "../../images/client logos/contravest.avif";
import costco from "../../images/client logos/costco.avif";
import frampton from "../../images/client logos/frampton.avif";
import hernando from "../../images/client logos/hernando.avif";
import lennar from "../../images/client logos/lennar.avif";
import robins from "../../images/client logos/robins.avif";
import roomstogo from "../../images/client logos/roomstogo.avif";
import wawa from "../../images/client logos/wawa.avif";
import bjs from "../../images/client logos/bjs.png";
import edTaylor from "../../images/client logos/edTaylor.png";
import whartonSmith from "../../images/client logos/wharton.png";
import williamA from "../../images/client logos/williamA.png";
import academy from "../../images/client logos/academy.png";
import hmlogo from "../../images/client logos/hmlogo.png";
import BattenShaw from "../../images/client logos/BattenShaw.png";
import BrooksvilleRegionalAirport from "../../images/client logos/BrooksvilleRegionalAirport.png";
import DadeCity from "../../images/client logos/DadeCity.png";
import DobbsEquipment from "../../images/client logos/DobbsEquipment.png";
import HCA from "../../images/client logos/HCA.png";
import Kohls from "../../images/client logos/Kohls.png";
import MettlerToledo from "../../images/client logos/MettlerToledo.png";
import OReily from "../../images/client logos/OReilly.png";
import Publix from "../../images/client logos/Publix.png";
import Redstone from "../../images/client logos/Redstone.png";
import RestaurantDepot from "../../images/client logos/RestaurantDepot.png";
import Rooker from "../../images/client logos/Rooker.png";
import Walmart from "../../images/client logos/Walmart.png";

import ClientLogoSlider from "../../components/ClientLogoSlider/ClientLogoSlider";

function Home() {
  return (
    <>
      <Hero />

      <div className="site-main">
        <section className="ttm-row about-section clearfix">
          <div className="container about-container">
            <div className="row">
              <div className="col-lg-6">
                <div className="padding_top40">
                  <div className="section-title">
                    <div className="title-header">
                      <h3>Who We Are</h3>
                      <h2 className="title">
                        Our Commitment to <span>Quality</span> and{" "}
                        <span>Service</span> at BRW Contracting.
                      </h2>
                    </div>
                    <div className="title-desc">
                      <p>
                        Established in 2001, BRW is a full-service site
                        development company located in Land O' Lakes, Florida.
                        We are proud to be a leader in providing high-quality
                        construction services to our customers. Our primary goal
                        is to create successful partnerships with our customers
                        and build long lasting relationships that are built on
                        integrity and trust by exceeding expectations through
                        exceptional planning and performance.
                      </p>
                    </div>
                  </div>
                  <div className="home-container">
                    <div
                      className="col-sm-6 company-list"
                      style={{ width: "85%" }}
                    >
                      <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor margin_top10 d-grid">
                        <li className="pb-3">
                          <i className="fa fa-long-arrow-right" />
                          <span className="ttm-list-li-content">
                            BRW is a comprehensive site development and
                            construction company based in Land O' Lakes, FL.
                          </span>
                        </li>
                        <li className="pb-3">
                          <i className="fa fa-long-arrow-right" />
                          <span className="ttm-list-li-content">
                            Our team has decades of experience in the
                            construction and development industry. We have
                            successfully completed hundreds of projects of all
                            sizes and complexities.
                          </span>
                        </li>
                        <li className="pb-3">
                          <i className="fa fa-long-arrow-right" />
                          <span className="ttm-list-li-content">
                            We offer a range of services such as land clearing,
                            earthwork, underground utilities, concrete, roadway
                            construction, paving and striping, as well as
                            curbing and flatwork.
                          </span>
                        </li>
                        <li className="pb-3">
                          <i className="fa fa-long-arrow-right" />
                          <span className="ttm-list-li-content">
                            We adhere to the highest industry standards and
                            regulations and prioritize safety.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-sm-4">
                      <div className="button">
                        <a
                          className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-dark margin_top40 float-end"
                          href="/about"
                        >
                          More About Us
                          <i className="fa fa-long-arrow-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-lg-6 d-flex align-items-center">
                <div className="padding_left30 res-575-padding_left0 res-575-margin_top30 res-575-margin_bottom30 res-991-margin_top30">
                  <div className="ttm_single_image-wrapper imagestyle-two">
                    <img
                      className="img-fluid auto_size"
                      src={Photo1}
                      alt=""
                      height={546}
                      width={720}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="rightAnim"
          className="ttm-row fid-section ttm-bgimage-yes bg-img5 ttm-bg ttm-bgcolor-darkgrey clearfix"
        >
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="ttm-fid inside ttm-fid-with-icon ttm-fid-view-lefticon style2">
                      <div className="ttm-fid-icon-wrapper">
                        <i className="flaticon flaticon-renovation" />
                      </div>
                      <div className="ttm-fid-contents">
                        <h4 className="ttm-fid-inner">
                          <span
                            data-appear-animation="animateDigits"
                            data-from={0}
                            data-to={23}
                            data-interval={1}
                            data-before=""
                            data-before-style="sup"
                            data-after=""
                            data-after-style="sub"
                            className="numinate"
                          >
                            23
                          </span>
                        </h4>
                        <h3 className="ttm-fid-title">Years Experience</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="ttm-fid inside ttm-fid-with-icon ttm-fid-view-lefticon style2">
                      <div className="ttm-fid-icon-wrapper">
                        <i className="flaticon flaticon-building-1" />
                      </div>
                      <div className="ttm-fid-contents">
                        <h4 className="ttm-fid-inner">
                          <span
                            data-appear-animation="animateDigits"
                            data-from={0}
                            data-to={550}
                            data-interval={5}
                            data-before=""
                            data-before-style="sup"
                            data-after=""
                            data-after-style="sub"
                            className="numinate"
                          >
                            550+
                          </span>
                          +
                        </h4>
                        <h3 className="ttm-fid-title">Projects</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="ttm-fid inside ttm-fid-with-icon ttm-fid-view-lefticon style2">
                      <div className="ttm-fid-icon-wrapper">
                        <i className="flaticon flaticon-workers" />
                      </div>
                      <div className="ttm-fid-contents">
                        <h4 className="ttm-fid-inner">
                          <span
                            data-appear-animation="animateDigits"
                            data-from={0}
                            data-to={500}
                            data-interval={5}
                            data-before=""
                            data-before-style="sup"
                            data-after="+"
                            data-after-style="sub"
                            className="numinate"
                          >
                            500
                          </span>
                          +
                        </h4>
                        <h3 className="ttm-fid-title">Team members</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="ttm-fid inside ttm-fid-with-icon ttm-fid-view-lefticon style2">
                      <div className="ttm-fid-icon-wrapper">
                        <i className="flaticon flaticon-design-team" />
                      </div>
                      <div className="ttm-fid-contents">
                        <h4 className="ttm-fid-inner">
                          <span
                            data-appear-animation="animateDigits"
                            data-from={0}
                            data-to={99}
                            data-interval={3}
                            data-before=""
                            data-before-style="sup"
                            data-after="+"
                            data-after-style="sub"
                            className="numinate"
                          >
                            99
                          </span>
                          % +
                        </h4>
                        <h3 className="ttm-fid-title">Self Performance</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="leftAnim"
          className="ttm-row mt_340 res-991-margin_top0 bg-layer-equal-height clearfix ttm-bgcolor-grey"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title title-style-center_text">
                      <div className="other-title-header">
                        <h3>Keep up to date with the latest</h3>
                        <h2 className="title">
                          BRW <span>NEWS</span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="elfsight-app-67492891-3b7d-47dc-949f-3c648b8fa5f5"></div>
          </div>
        </section>

        <section className="ttm-row valuable-section ttm-bgcolor-grey clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title title-style-center_text">
                      <div className="other-title-header">
                        <h2 className="title">
                          Some Of Our <span>Valued</span> Clients
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <ClientLogoSlider />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Home;
